.App {
  text-align: left;
}

.section {
  height: 100vh;
}


@media (max-width: 768px) {
  .section {
    height: auto;
    min-height: 100vw;
  }
}